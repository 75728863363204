import { render, staticRenderFns } from "./DepartmentCampaigns.vue?vue&type=template&id=6f938ddc&lang=html&"
import script from "./DepartmentCampaigns.vue?vue&type=script&lang=js&"
export * from "./DepartmentCampaigns.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports